/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordRequest } from '../models/ChangePasswordRequest';
import type { ChangeUserRequest } from '../models/ChangeUserRequest';
import type { FeedbackRequest } from '../models/FeedbackRequest';
import type { Formats } from '../models/Formats';
import type { LoginResponse } from '../models/LoginResponse';
import type { RegisterRequest } from '../models/RegisterRequest';
import type { RegisterResponse } from '../models/RegisterResponse';
import type { Request } from '../models/Request';
import type { UploadResponse } from '../models/UploadResponse';
import type { UserProfile } from '../models/UserProfile';
import type { UserResponse } from '../models/UserResponse';
import type { UsersResponse } from '../models/UsersResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserService {
  /**
   * Register a user with username and password
   * @returns RegisterResponse User created
   * @throws ApiError
   */
  public static postRegister({
    requestBody,
  }: {
    /**
     * Username, email and password for registration.
     */
    requestBody: RegisterRequest,
  }): CancelablePromise<RegisterResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/register',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid request.`,
        500: `Registration failed.`,
      },
    });
  }
  /**
   * Activate registered user using activation code
   * @returns any User activated.
   * @throws ApiError
   */
  public static getActivate({
    code,
  }: {
    code: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/activate/{code}',
      path: {
        'code': code,
      },
      errors: {
        410: `Activation code invalid or expired.`,
        500: `Activation failed.`,
      },
    });
  }
  /**
   * Login a user with username and password
   * @returns LoginResponse OK
   * @throws ApiError
   */
  public static postLogin({
    requestBody,
  }: {
    /**
     * Username and password for authorization.
     */
    requestBody: Request,
  }): CancelablePromise<LoginResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Authorization failed.`,
      },
    });
  }
  /**
   * Logout user
   * @returns any OK
   * @throws ApiError
   */
  public static getLogout(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/logout',
    });
  }
  /**
   * Validate JWT token.
   * @returns LoginResponse OK
   * @throws ApiError
   */
  public static postAuthorize({
    authorization,
    requestBody,
  }: {
    authorization?: string,
    /**
     * List of required roles and permissions.
     */
    requestBody?: Array<string>,
  }): CancelablePromise<LoginResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/authorize',
      headers: {
        'Authorization': authorization,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Authorization failed.`,
      },
    });
  }
  /**
   * Validate JWT token and return user details.
   * @returns UserResponse OK
   * @throws ApiError
   */
  public static getUser(): CancelablePromise<UserResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user',
      errors: {
        401: `Authorization failed.`,
      },
    });
  }
  /**
   * Returns user profile for given ID.
   * @returns UserProfile OK
   * @throws ApiError
   */
  public static getUser1({
    id,
  }: {
    id?: string,
  }): CancelablePromise<UserProfile> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/user/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad request.`,
        404: `Not found.`,
        500: `Internal server Error.`,
      },
    });
  }
  /**
   * Change username.
   * @returns any OK.
   * @throws ApiError
   */
  public static patchUserUsername({
    requestBody,
  }: {
    /**
     * Provides new username.
     */
    requestBody: ChangeUserRequest,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/user/username',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid request`,
        401: `Authorization failed.`,
        500: `Internal server error.`,
      },
    });
  }
  /**
   * Change password.
   * @returns any OK.
   * @throws ApiError
   */
  public static patchUserPassword({
    requestBody,
  }: {
    /**
     * Provides old and new passwords.
     */
    requestBody: ChangePasswordRequest,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/user/password',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid request`,
        401: `Authorization failed.`,
        500: `Internal server error.`,
      },
    });
  }
  /**
   * Returns empty list of users and the total count.
   * @returns UsersResponse OK
   * @throws ApiError
   */
  public static getUsers(): CancelablePromise<UsersResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/users',
    });
  }
  /**
   * Post a feedback or support request.
   * @returns any OK
   * @throws ApiError
   */
  public static postFeedback({
    requestBody,
  }: {
    /**
     * Contains necessary information such as message, user details, attachments, etc.
     */
    requestBody?: FeedbackRequest,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/feedback',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Invalid request data.`,
        500: `Internal server error.`,
      },
    });
  }
  /**
   * Upload a file, for example a screenshot, for the feedback. Only certain filetypes are supported.
   * @returns UploadResponse OK
   * @throws ApiError
   */
  public static postFeedbackUpload({
    formData,
  }: {
    formData?: {
      fileName?: Blob;
    },
  }): CancelablePromise<UploadResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/feedback/upload',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Invalid request data.`,
        500: `Internal server error.`,
      },
    });
  }
  /**
   * Returns the list of supported file formats.
   * @returns Formats OK
   * @throws ApiError
   */
  public static getFeedbackFormats(): CancelablePromise<Formats> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/feedback/formats',
    });
  }
}
