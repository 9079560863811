import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const Main = React.lazy(() => import('@pages/Main/Main').then((res) => ({ default: res.Main })));
const Players = React.lazy(() => import('@pages/Players/Players').then((res) => ({ default: res.Players })));
const Profile = React.lazy(() => import('@pages/Players/id/Profile').then((res) => ({ default: res.Profile })));
const Tournaments = React.lazy(() =>
    import('@/pages/Tournaments/Tournaments').then((res) => ({ default: res.Tournaments }))
);
const Tournament = React.lazy(() =>
    import('@/pages/Tournaments/id/Tournament').then((res) => ({ default: res.Tournament }))
);
const Calculator = React.lazy(() => import('@pages/Calculator').then((res) => ({ default: res.Calculator })));
const SignIn = React.lazy(() => import('@pages/Auth/SignIn').then((res) => ({ default: res.SignIn })));
const SignUp = React.lazy(() => import('@pages/Auth/SignUp').then((res) => ({ default: res.SignUp })));
const Activation = React.lazy(() => import('@pages/Auth/Activation').then((res) => ({ default: res.Activation })));
const About = React.lazy(() => import('@pages/About/About').then((res) => ({ default: res.About })));
const Video = React.lazy(() => import('@pages/Video/Video').then((res) => ({ default: res.Video })));
const ThrowError = React.lazy(() => import('@pages/ThrowError').then((res) => ({ default: res.ThrowError })));
// const Viewer = React.lazy(() => import('@pages/Viewer/Viewer').then((res) => ({ default: res.Viewer })));
const Server = React.lazy(() => import('@pages/Server/Server').then((res) => ({ default: res.Server })));
const SereverGame = React.lazy(() =>
    import('@/pages/Server/Game/ServerGame').then((res) => ({ default: res.ServerGame }))
);

export const Router: React.FC = () => (
    <Routes>
        <Route
            path="/"
            element={
                <Suspense fallback={<>...</>}>
                    <Main />
                </Suspense>
            }
        />
        <Route
            path="/players"
            element={
                <Suspense fallback={<>...</>}>
                    <Players />
                </Suspense>
            }
        />
        <Route
            path="/players/:id"
            element={
                <Suspense fallback={<>...</>}>
                    <Profile />
                </Suspense>
            }
        />
        <Route
            path="/tournaments"
            element={
                <Suspense fallback={<>...</>}>
                    <Tournaments />
                </Suspense>
            }
        />
        <Route
            path="/tournaments/:id"
            element={
                <Suspense fallback={<>...</>}>
                    <Tournament />
                </Suspense>
            }
        />
        <Route
            path="/calculator"
            element={
                <Suspense fallback={<>...</>}>
                    <Calculator />
                </Suspense>
            }
        />
        <Route
            path="/sign-in"
            element={
                <Suspense fallback={<>...</>}>
                    <SignIn />
                </Suspense>
            }
        />
        <Route
            path="/sign-up"
            element={
                <Suspense fallback={<>...</>}>
                    <SignUp />
                </Suspense>
            }
        />
        <Route
            path="/sign-up/:code"
            element={
                <Suspense fallback={<>...</>}>
                    <Activation />
                </Suspense>
            }
        />
        <Route
            path="/about"
            element={
                <Suspense fallback={<>...</>}>
                    <About />
                </Suspense>
            }
        />
        <Route
            path="/go-glass"
            element={
                <Suspense fallback={<>...</>}>
                    <Video />
                </Suspense>
            }
        />
        <Route
            path="/throw-error"
            element={
                <Suspense fallback={<>...</>}>
                    <ThrowError />
                </Suspense>
            }
        />
        {/* <Route
            path="/viewer"
            element={
                <Suspense fallback={<>...</>}>
                    <Viewer />
                </Suspense>
            }
        /> */}
        <Route
            path="/server"
            element={
                <Suspense fallback={<>...</>}>
                    <Server />
                </Suspense>
            }
        />
        <Route
            path="/server/:id"
            element={
                <Suspense fallback={<>...</>}>
                    <SereverGame />
                </Suspense>
            }
        />
    </Routes>
);
